import { useEffect, useSyncExternalStore } from "react";

type ClaimrStatus = "auth" | "not-auth";

let claimrAuthStatus: ClaimrStatus =
  (window.localStorage.getItem("claimr-auth-status") as ClaimrStatus) ||
  "not-auth";
const subscribers = new Set<VoidFunction>();

export const claimrAuthStatusStore = {
  get() {
    return claimrAuthStatus;
  },
  set(newValue: ClaimrStatus) {
    claimrAuthStatus = newValue;
    if (newValue) {
      localStorage.setItem("claimr-auth-status", newValue);
    } else {
      localStorage.removeItem("claimr-auth-status");
    }
    subscribers.forEach((callback) => callback());
  },
  subscribe(callback: VoidFunction) {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  },
};

export const useClaimrAuthStatusStore = () => {
  const claimrAuthStatus = useSyncExternalStore(
    claimrAuthStatusStore.subscribe,
    claimrAuthStatusStore.get
  );

  return [claimrAuthStatus, claimrAuthStatusStore.set] as const;
};

export const useSyncClaimrAuthStatusStore = () => {
  const [_, setSession] = useClaimrAuthStatusStore();

  useEffect(() => {
    const cb = (event: StorageEvent) => {
      if (event.key === "claimr-auth-status") {
        console.log(event.key, event.newValue);
        setSession((event.newValue as ClaimrStatus) ?? undefined);
      }
    };
    window.addEventListener("storage", cb);

    return () => {
      window.removeEventListener("storage", cb);
    };
  }, [setSession]);
};

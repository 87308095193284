import { Address, zeroAddress } from "viem";

export type SBTContracts = {
  dapp: Address;
  prover: string;
  registry: Address;
  verificationSBT: Address;
};

export type Contracts = {
  adult18Plus: SBTContracts;
  nonSanctionedJurisdiction: SBTContracts;
  nonUS: SBTContracts;
  twitter100: SBTContracts;
  twitter1000: SBTContracts;
  twitter10000: SBTContracts;
  twitterBefore2020: SBTContracts;
  twitterIn2024: SBTContracts;
  zkKYC: SBTContracts;
};

export const contracts: Record<number | string, Contracts> = {
  "41238": {
    twitter100: {
      registry: "0x9Fe589e647533E9c5D6Fe0363b3eD18B170cE506",
      verificationSBT: "0xe79b324a977484b958D5aF31476D8023CE68A6B2",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterFollowersCountProof.0x1A29924A1d29B179B71Bd2D50a5Ca872c6035f31.prover.json",
      dapp: "0xAe126E810A0B035a50821eBC92fC73352f3F8852",
    },
    twitter1000: {
      registry: "0x9Fe589e647533E9c5D6Fe0363b3eD18B170cE506",
      verificationSBT: "0x55B18bC738d7ecb939814dDc434361Ecb7d39448",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterFollowersCountProof.0x1A29924A1d29B179B71Bd2D50a5Ca872c6035f31.prover.json",
      dapp: "0xAe126E810A0B035a50821eBC92fC73352f3F8852",
    },
    twitter10000: {
      registry: "0x9Fe589e647533E9c5D6Fe0363b3eD18B170cE506",
      verificationSBT: "0x71A289BC86704c31d75368B4b63FE85f6Fa8486F",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterFollowersCountProof.0x1A29924A1d29B179B71Bd2D50a5Ca872c6035f31.prover.json",
      dapp: "0xAe126E810A0B035a50821eBC92fC73352f3F8852",
    },
    twitterBefore2020: {
      verificationSBT: "0x1e575881CfeeF132Ef928CF6a369c53638683685",
      dapp: "0x7830E296c33d88b93376413aB952a737FC332c13",
      registry: "0x9Fe589e647533E9c5D6Fe0363b3eD18B170cE506",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterCreationTimeProof.0x4af769ea4F45166E8453b67340781dF0E29E6e7A.prover.json",
    },
    twitterIn2024: {
      verificationSBT: "0xD5E27e0cE400FD70B7543Ee686951e926737793e",
      dapp: "0x7830E296c33d88b93376413aB952a737FC332c13",
      registry: "0x9Fe589e647533E9c5D6Fe0363b3eD18B170cE506",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterCreationTimeProof.0x4af769ea4F45166E8453b67340781dF0E29E6e7A.prover.json",
    },
    zkKYC: {
      registry: "0x29eFd381054edDb68163C0D34546d9EaE9710091",
      verificationSBT: "0x108f39364e8bCFeA45Bc3d3965f0A06f89bA2B06",
      dapp: "0xeC7D005Fed567EDCbA285f9585dC20920F4dE98f",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/zkKYC.0x5cAF5b0D2e64b0ff3b08eCbe4eB3448c8b8D3D66.json",
    },
    nonUS: {
      registry: "0x29eFd381054edDb68163C0D34546d9EaE9710091",
      verificationSBT: "0x108f39364e8bCFeA45Bc3d3965f0A06f89bA2B06",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/ageCitizenshipKYC.0xFB102F1583eF27bc2cE72AF08dCfB8a783102d3A.json",
      dapp: "0xC15c258809d93511996739328169bd4e244a5C35",
    },
    nonSanctionedJurisdiction: {
      registry: "0x29eFd381054edDb68163C0D34546d9EaE9710091",
      verificationSBT: "0x108f39364e8bCFeA45Bc3d3965f0A06f89bA2B06",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/ageCitizenshipKYC.0xFB102F1583eF27bc2cE72AF08dCfB8a783102d3A.json",
      dapp: "0xFc98afe59cDDB6b8E6cdD09210475B1B89DD9235",
    },
    adult18Plus: {
      registry: "0x29eFd381054edDb68163C0D34546d9EaE9710091",
      verificationSBT: "0x108f39364e8bCFeA45Bc3d3965f0A06f89bA2B06",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/ageCitizenshipKYC.0xFB102F1583eF27bc2cE72AF08dCfB8a783102d3A.json",
      dapp: "0xE1F8832BD48a2Eb75DD19639B5859a243292c8ef",
    },
  },
  "9302": {
    twitter100: {
      registry: zeroAddress,
      verificationSBT: zeroAddress,
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterFollowersCountProof.0x1A29924A1d29B179B71Bd2D50a5Ca872c6035f31.prover.json",
      dapp: zeroAddress,
    },
    twitter1000: {
      registry: zeroAddress,
      verificationSBT: zeroAddress,
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterFollowersCountProof.0x1A29924A1d29B179B71Bd2D50a5Ca872c6035f31.prover.json",
      dapp: zeroAddress,
    },
    twitter10000: {
      registry: zeroAddress,
      verificationSBT: zeroAddress,
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterFollowersCountProof.0x1A29924A1d29B179B71Bd2D50a5Ca872c6035f31.prover.json",
      dapp: zeroAddress,
    },
    twitterBefore2020: {
      verificationSBT: zeroAddress,
      dapp: zeroAddress,
      registry: zeroAddress,
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterCreationTimeProof.0x4af769ea4F45166E8453b67340781dF0E29E6e7A.prover.json",
    },
    twitterIn2024: {
      verificationSBT: zeroAddress,
      dapp: zeroAddress,
      registry: zeroAddress,
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/twitterCreationTimeProof.0x4af769ea4F45166E8453b67340781dF0E29E6e7A.prover.json",
    },
    zkKYC: {
      registry: "0xc2032b11b79B05D1bd84ca4527D2ba8793cB67b2",
      verificationSBT: "0x8DbCB0a08CFe38Ee20326471E4260be4BfD321b6",
      dapp: "0x6f640Fd66291DD9C8976db12721BF612Db2d54E8",
      prover:
        "https://galactica-trusted-setup.s3.eu-central-1.amazonaws.com/zkKYC.0x7994BA7820758E9CAE9D1F72C3A727eF055400E6.prover.json",
    },
    nonUS: {
      verificationSBT: "0x8DbCB0a08CFe38Ee20326471E4260be4BfD321b6",
      prover: "",
      registry: "0xc2032b11b79B05D1bd84ca4527D2ba8793cB67b2",
      dapp: zeroAddress,
    },
    nonSanctionedJurisdiction: {
      registry: "0xc2032b11b79B05D1bd84ca4527D2ba8793cB67b2",
      verificationSBT: "0x8DbCB0a08CFe38Ee20326471E4260be4BfD321b6",
      prover: "",
      dapp: zeroAddress,
    },
    adult18Plus: {
      registry: "0xc2032b11b79B05D1bd84ca4527D2ba8793cB67b2",
      verificationSBT: "0x8DbCB0a08CFe38Ee20326471E4260be4BfD321b6",
      prover: "",
      dapp: zeroAddress,
    },
  },
};

const iranCountryHash =
  "1579299172692188755949140021063317367780202106533086885574412920637882772641";
const usCountryHash =
  "4020996060095781638329708372473002493481697479140228740642027622801922135907";

export const complianceParamsMap = {
  zkKYC: {},
  adult18Plus: {
    ageThreshold: "18",
    countryExclusionList: ["1", ...Array.from({ length: 19 }).fill("0")],
  },
  nonUS: {
    ageThreshold: "0",
    countryExclusionList: [
      "1",
      usCountryHash,
      ...Array.from({ length: 18 }).fill("0"),
    ],
  },
  nonSanctionedJurisdiction: {
    ageThreshold: "0",
    countryExclusionList: [
      "1",
      "9486630697425279445612000328555997701072236804044002640421504736279952343849",
      "547839063311530791297346366602012231118231039495046022201451266576542019981",
      "1579299172692188755949140021063317367780202106533086885574412920637882772641",
      "15786504372354808533203054903037879362957914009841341294369722265500808724161",
      "18098589439152333042030315964543001205473276620580857922031117838022683070773",
      "17882161724418421136016086651044337323005492820108220042903835134630571567582",
      "19037000831055754368902371788114440549819591541656477098846720297068933172341",
      "3156213415302150987418939084880172676939570074809887304771992132488364418105",
      "7824020351098471663236983398988921664549883380587672977122056690155605731353",
      "11538185374913968762441603994434233210490918178265725246140261953948645390719",
      "14058317686321252753147547041444294673146533675385779577860102322719355725343",
      "13306981694983268332905708603732826646559946273693634965149993697926698182329",
      "19333591095214086442916257305432634481215335428896180650986834320269790757590",
      "20619947529070564887818900251608925376793962020210427486695837804394155347693",
      "9807967243963826593365864035071994072702054122363333136531333633115206257286",
      "0",
      "0",
      "0",
      "0",
    ],
  },
  twitter100: {
    followersCountThreshold: 100,
  },
  twitter1000: {
    followersCountThreshold: 1000,
  },
  twitter10000: {
    followersCountThreshold: 10000,
  },
  twitterBefore2020: {
    creationTimeLowerBound: 0,
    creationTimeUpperBound: 1577836800,
  },
  twitterIn2024: {
    creationTimeLowerBound: 1704067200,
    creationTimeUpperBound: 1735689600,
  },
} as const;

export type ComplianceProof = keyof typeof complianceParamsMap;

/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useMutation, useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { Address, Hex } from "viem";
import { useAccount, useChainId } from "wagmi";

import { sleep } from "./sleep";

type ClaimrUserInfo = {
  name: Address;
  ref_id: null | string | undefined;
  ref_xp: number;
  ugc_xp: number;
  xp: number;
  xp_mul: number;
};

const fetchClaimrScore = async (accountAddress: Address) => {
  const companyId = "fJHne4VR";
  const companyPublicKey = "ZySiR_hIFvM75zSe";
  // @ts-ignore
  const response: ClaimrUserInfo = await window.claimr.get_user_info(
    companyId,
    companyPublicKey,
    accountAddress,
    "web3"
  );
  return response;
};

export const claimrConnect = (
  address: Address,
  signature: Hex,
  message: string
) => {
  console.log("CLAIMR: connect_wallet");
  //@ts-ignore
  return window.claimr.connect_wallet(address, signature, message);
};

const tasksMap = {
  join: "MZEzAKm9",
  installSnap: "3Oao_m4B",
} as const;
type QuestName = keyof typeof tasksMap;

export const claimrCompleteTask = (
  task: Omit<string, QuestName> | QuestName
) => {
  const taskId: string = tasksMap[task as QuestName] ?? task;
  console.log("CLAIMR: ", `complete_task with id${taskId}`);
  //@ts-ignore
  return window.claimr.complete_task(taskId);
};

export const claimrLogout = async () => {
  console.log("CLAIMR: logout");
  // @ts-ignore
  window.claimr.logout();
  await sleep(1000);
};

export const useClaimrLogoutMutation = () => {
  return useMutation({
    mutationFn: async () => {
      await claimrLogout();
      return;
    },
  });
};

export const useClaimrScoreQuery = () => {
  const { address } = useAccount();
  const chainId = useChainId();
  return useQuery({
    queryKey: ["claimr", "score", chainId, address],
    queryFn: async () => {
      invariant(address, "address is undefined");
      return fetchClaimrScore(address);
    },
    enabled: Boolean(address),
  });
};
